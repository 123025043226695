<template>
    <div style="background:#FCFCFC">
        <div class="container position-relative">
            <router-link
                :to="{ name: 'Home' }"
                class="position-absolute"
                style="left:2rem;opacity:0.5;color:black;text-decoration:none"
            >
                <span class="triangle"></span> back
            </router-link>
            <div
                class="row d-flex flex-column justify-content-center flex-lg-row mx-xl-5 py-5"
            >
                <!-- order -->
                <div
                    class="
                        bg-white
                        rounded
                        shadow-sm
                        p-2
                        col-lg-8
                        col-xl-6
                        col-12
                        mb-3
                    "
                >
                    <h3 class="m-0 fw-bold">Order</h3>
                    <div
                        class="row my-5"
                        v-for="(order, index) in orders"
                        :key="index"
                    >
                        <div class="col-md-4 col-5">
                            <div>{{ order.order_no }}</div>
                            <div style="font-size:0.8rem">
                                {{ getOrderedDate(order.created_at) }}
                            </div>
                        </div>
                        <div class="col-md-4 col-4">
                            <font-awesome-icon
                                :class="{
                                    'icon-color':
                                        order.status === 4 ||
                                        order.status === 6,
                                }"
                                :icon="getIcon(order.status)"
                            />
                            {{ order.prepareStatus }}
                        </div>
                        <div
                            class="cursor col-md-4 col-3 text-end"
                            @click="orderDetail(order.id)"
                        >
                            <span v-if="order.status === 2">Make Payment</span>
                            <span v-else>Detail</span>
                            <font-awesome-icon icon="chevron-right" />
                        </div>
                    </div>
                    <!-- show more button -->
                    <div class="text-center mb-5">
                        <!-- <p>{{ current_count }}/{{ total_count }}</p> -->
                        <button
                            v-if="has_more_page"
                            class="btn"
                            @click="showMore"
                        >
                            Show More
                        </button>
                    </div>
                </div>

                <!-- user -->
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="order-points py-3 mb-3">
                        <div
                            class="d-flex align-items-center justify-content-center gap-5"
                        >
                            <div
                                class="d-flex flex-column align-items-center justify-content-center"
                            >
                                <img
                                    src="@/assets/img/Royalty.png"
                                    alt=""
                                    style="width: 2.5rem;"
                                />
                                <p class="m-0">Loyalty</p>
                                <p class="m-0 fs-5 fw-bold">
                                    {{ user.product_point }}
                                </p>
                            </div>
                            <div
                                class="d-flex flex-column align-items-center justify-content-center"
                            >
                                <img
                                    src="@/assets/img/Mining.png"
                                    alt=""
                                    style="width: 2.5rem;"
                                />
                                <p class="m-0">Mining</p>
                                <p class="m-0 fs-5 fw-bold">
                                    {{ user.point }}
                                </p>
                            </div>
                            <div
                                class="d-flex flex-column align-items-center justify-content-center"
                            >
                                <img
                                    src="@/assets/img/Referal1.png"
                                    alt=""
                                    style="width: 2.5rem;"
                                />
                                <p class="m-0">Referal</p>
                                <p class="m-0 fs-5 fw-bold">
                                    {{ user.referral_point }}
                                </p>
                            </div>
                        </div>
                        <div class="text-center cursor">
                            Redeem Ticket
                            <font-awesome-icon icon="chevron-right" />
                        </div>
                    </div>
                    <div class="p-3 bg-white rounded">
                        <div>
                            <h4 class="m-0 fw-bold" style="color:#3CB207">
                                {{ user.name }}
                            </h4>
                            <span>ID: {{ user.id }} </span>
                        </div>
                        <div class="my-3">
                            <p class="fw-bold m-0">Address</p>
                            <span> {{ user.shipping_address }} </span>
                        </div>
                        <div class="my-3">
                            <p class="fw-bold m-0">Email</p>
                            <span>{{ user.email }}</span>
                        </div>
                        <div class="my-3">
                            <p class="fw-bold m-0">Phone</p>
                            <span>{{ user.phone }}</span>
                        </div>
                        <button
                            class="order-history-btn w-100 py-2"
                            @click="editProfile"
                        >
                            <font-awesome-icon icon="edit" /> Edit Profile
                        </button>
                    </div>
                    <div class="p-3 bg-white rounded border-bottom border-top">
                        <div>
                            <h4 class="m-0 fw-bold" style="color:#3CB207">
                                Delivery Address
                            </h4>
                        </div>
                        <div
                            class="my-3 d-flex"
                            v-for="(addr, index) in user.shipping_addresses"
                            :key="index"
                        >
                            <div>
                                <p class="fw-bold m-0">
                                    {{ addr.reference }}
                                </p>
                                <span
                                    >{{ addr.reference_name }} -
                                    {{ addr.reference_phone }}</span
                                >
                                <p>{{ addr.reference_address }}</p>
                            </div>
                            <div class="d-flex gap-2">
                                <font-awesome-icon
                                    class="cursor"
                                    icon="edit"
                                    @click="editAddress(addr)"
                                />
                                <font-awesome-icon
                                    class="cursor"
                                    icon="trash"
                                    @click="deleteAddress(addr.id)"
                                />
                            </div>
                        </div>
                        <button
                            class="order-history-btn w-100 py-2"
                            @click="editAddress(null)"
                        >
                            <font-awesome-icon icon="plus-circle" /> Add new
                            delivery address
                        </button>
                    </div>
                    <ShippingAddressForm
                        v-model="currentAddress"
                        @prepared="submitAddress"
                    />
                    <div
                        class="p-3 bg-white rounded d-flex justify-content-around"
                    >
                        <div
                            class="d-flex flex-column align-items-center"
                            style="margin-left:-1rem"
                            @click="changePassword"
                        >
                            <lock-open-icon
                                class="icon cursor rounded-circle"
                            />
                            <p class="m-0" style="font-size:0.8rem">
                                Change Password
                            </p>
                        </div>
                        <div
                            class="d-flex flex-column align-items-center"
                            style="margin-left:-2.5rem"
                            @click="toWishlist"
                        >
                            <heart-icon class="icon cursor rounded-circle" />
                            <p class="m-0" style="font-size:0.8rem">Wishlist</p>
                        </div>
                        <div
                            class="d-flex flex-column align-items-center"
                            @click="logout"
                        >
                            <logout-icon class="icon cursor rounded-circle" />
                            <p class="m-0" style="font-size:0.8rem">Logout</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CommaPrice from '@/mixins/CommaPrice';
// query
import MyOrders from '../../graphql/queries/order/MyOrders.gql';
import OrderStatus from '../../graphql/queries/order/OrderStatus.gql';
import { mapGetters } from 'vuex';
import ShippingAddressForm from '../../components/reusable/ShippingAddressForm.vue';
import { onLogout } from '../../graphql/vue-apollo';
// mutation
import createShippingAddress from '../../graphql/mutations/auth/createShippingAddress.gql';
import updateShippingAddress from '../../graphql/mutations/auth/updateShippingAddress.gql';
import deleteShippingAddress from '../../graphql/mutations/auth/deleteShippingAddress.gql';
export default {
    mixins: [CommaPrice],
    components: {
        ShippingAddressForm,
    },
    data() {
        return {
            current_page: 1,
            has_more_page: false,
            show_more: false,
            active_id: null,
            has_active_id: false,
            scrollCount: 1,
            currentScrollHeight: 0,
            should_stop: false,
            statuses: [],
            currentAddress: {
                reference: '',
                reference_name: '',
                reference_phone: '',
                reference_address: '',
                is_save: true,
            },
        };
    },

    mounted() {
        // this.getStatus();
        if (this.$route.query && this.$route.query.order_id) {
            this.getHighLightOrder();
        } else {
            this.getOrders();
        }
    },
    computed: {
        ...mapGetters({
            orders: 'order/getOrders',
            isMakeOrder: 'order/getIsMakeOrder',
            user: 'auth/getUser',
            isLoggedIn: 'auth/isLoggedIn',
        }),
    },
    methods: {
        async getOrders() {
            try {
                const orders = await this.$apollo.query({
                    query: MyOrders,
                    fetchPolicy: 'no-cache',

                    variables: {
                        first: 10,
                        page: this.current_page,
                    },
                });

                if (orders) {
                    console.log('orders', orders);
                    this.$apollo
                        .query({
                            query: OrderStatus,
                        })
                        .then(response => {
                            this.statuses = response.data.status;
                            console.log(this.statuses);
                        })
                        .catch(error => {
                            console.log(error);
                        });
                    if (this.show_more) {
                        orders.data.me.userOrders.data.map(data => {
                            this.$store.commit('order/APPEND_ORDERS', data);
                        });
                    } else {
                        await this.$store.commit(
                            'order/ADD_ORDERS',
                            orders.data.me.userOrders.data,
                        );
                    }
                    this.has_more_page =
                        orders.data.me.userOrders.paginatorInfo.hasMorePages;
                }
            } catch (error) {
                console.log(error);
            }
        },
        showMore() {
            this.current_page++;
            this.show_more = true;
            this.getOrders();
        },

        async getHighLightOrder() {
            console.log('whiling!!!');
            while (!this.has_active_id) {
                try {
                    const orders = await this.$apollo.query({
                        query: MyOrders,
                        fetchPolicy: 'no-cache',

                        variables: {
                            first: 10,
                            page: this.current_page,
                        },
                    });

                    if (orders) {
                        console.log(orders);

                        orders.data.me.userOrders.data.map(data => {
                            if (data.id == this.$route.query.order_id) {
                                this.$store.commit('order/APPEND_ORDERS', data);
                                this.active_id = data.id;
                                this.has_active_id = true;
                                this.scrollCount++;
                                this.should_stop = true;
                            } else {
                                this.$store.commit('order/APPEND_ORDERS', data);
                                if (this.has_active_id) {
                                    this.scrollCount++;
                                }
                            }
                        });
                        this.has_more_page =
                            orders.data.me.userOrders.paginatorInfo.hasMorePages;
                        if (!this.has_active_id && this.has_more_page) {
                            this.current_page++;
                        }
                        if (this.should_stop || !this.has_more_page) {
                            break;
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            }

            this.$nextTick(() => {
                if (this.has_active_id) {
                    var heightLightTop = document.querySelector(
                        '.addHighlight',
                    );
                    heightLightTop.scrollIntoView(true);
                }
            });
        },
        getOrderedDate(timestamp, deliver = false) {
            const formatDate = new Date(timestamp).toLocaleString(undefined, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                weekday: 'long',
            });
            if (deliver) {
                const currentDate = new Date(formatDate);
                currentDate.setDate(currentDate.getDate() + 10);
                const deliverDate = new Date(currentDate).toLocaleString(
                    undefined,
                    {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        weekday: 'long',
                    },
                );
                return deliverDate;
            } else {
                return formatDate;
            }
        },

        closeMakeOrder() {
            this.$store.commit('order/MAKE_ORDER', false);
        },
        orderDetail(slug) {
            this.$router.push({
                name: 'OrderDetail',
                params: {
                    slug: slug,
                },
                query: {
                    slug: slug,
                },
            });
        },
        getIcon(sts) {
            return this.statuses.find(status => status.id === sts).icon;
        },
        editAddress(address = null) {
            if (address) {
                this.currentAddress = address;
                this.currentAddress.is_save = true;
            }
            this.$emit('openAddressModel', address);
        },
        submitAddress() {
            let id = this.currentAddress.id;
            delete this.currentAddress.id;
            delete this.currentAddress.__typename;
            if (!id) {
                this.$apollo
                    .mutate({
                        mutation: createShippingAddress,
                        variables: {
                            address: this.currentAddress,
                        },
                    })
                    .then(response => {
                        console.log(response);
                        this.user.shipping_addresses.push(
                            response.data.createShippingAddress,
                        );
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {
                this.$apollo
                    .mutate({
                        mutation: updateShippingAddress,
                        variables: {
                            id: id,
                            address: this.currentAddress,
                        },
                    })
                    .then(response => {
                        console.log(response);
                        this.$router.go(this.$router.currentRoute);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },
        deleteAddress(id) {
            this.$apollo
                .mutate({
                    mutation: deleteShippingAddress,
                    variables: {
                        id: id,
                    },
                })
                .then(response => {
                    console.log(response);
                    this.$router.go(this.$router.currentRoute);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        logout() {
            onLogout(this.$apollo.provider.defaultClient);
            this.$store.commit('auth/REMOVE_AUTH');
            this.myAccountDrop = false;
            this.$store.commit('cart/RESET_CART');
            if (this.$route.name !== 'Home') {
                this.$router.push({ name: 'Home' });
            }
        },
        toWishlist() {
            if (this.isLoggedIn) {
                this.$router.push({
                    name: 'WishList',
                });
            } else {
                this.$router.push({
                    name: 'Login',
                });
            }
        },
        editProfile() {
            if (this.isLoggedIn) {
                this.$router.push({
                    name: 'ProfileEdit',
                });
            }
        },
        changePassword() {
            if (this.isLoggedIn) {
                this.$router.push({
                    name: 'PasswordEdit',
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.order-points {
    background: transparent
        linear-gradient(180deg, #fffddd00 0%, #fffdddbf 100%) 0% 0% no-repeat
        padding-box;
    border: 1px solid #efd59c;
    border-radius: 10px;
    opacity: 1;
}
.icon-color {
    color: #3cb207;
}
.order-history-btn {
    background: #3cb207 0% 0% no-repeat padding-box;
    background: #3cb207 0% 0% no-repeat padding-box;
    border-radius: 10px;
    color: #fff;
    opacity: 1;
    border: none;
}
.icon {
    background: #3cb207;
    color: #fff;
    padding: 0.4rem;
    width: 2.5rem;
    height: 2.5rem;
}
.triangle::after {
    position: absolute;
    top: 50%;
    right: 115%;
    margin-top: -6px;
    content: ' ';
    width: 0;
    height: 0;
    border-left: 0.4rem solid transparent;
    border-right: 0.4rem solid black;
    border-bottom: 0.4rem solid transparent;
    border-top: 0.4rem solid transparent;
}
</style>
