<template>
    <orders />
</template>

<script>
import Orders from '../../components/profile/Orders.vue';

export default {
    components: {
        orders: Orders,
    },
};
</script>
